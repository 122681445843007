/* eslint-disable react/style-prop-object */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
// import Img from 'gatsby-image';
// import styled from 'styled-components';
import { Box, Flex } from "rebass";
import Layout from "../components/Layout";
import { Section } from "../components/Section";
import Container from "../components/Container";
import { InfoBox } from "../components/InfoBox";

const InfoBoxWrapper = ({ children, ...rest }) => (
  <Box
    sx={{
      width: "100%",
      display: "grid",
      gap: 2,
      gridTemplate: ["auto / 1fr", "auto / 1fr 1fr 1fr"],
    }}
    {...rest}
  >
    {children}
  </Box>
);

export const PagePricelistTemplate = ({
  title,
  content,
  price_includes: priceIncludes,
  price_not_includes: priceNotIncludes,
  check_in_out: checkInOut,
  basic_prices: basicPrices,
  special_offers: specialOffers,
}) => (
  <Section>
    <Container>
      <Flex flexDirection={["column", "row"]}>
        <Box width={[1]}>
          <h1>{`Ceník ${title}`}</h1>

          <Box sx={{ display: "grid", gap: 2 }}>
            {basicPrices && (
              <InfoBoxWrapper>
                {basicPrices.map((price) => {
                  if (price.content) {
                    return (
                      <InfoBox>
                        <div
                          dangerouslySetInnerHTML={{ __html: price.content }}
                        />
                      </InfoBox>
                    );
                  }
                  return null;
                })}
              </InfoBoxWrapper>
            )}

            {specialOffers && (
              <InfoBoxWrapper>
                {specialOffers.map((offer) => {
                  if (offer.content) {
                    return (
                      <InfoBox>
                        <div
                          dangerouslySetInnerHTML={{ __html: offer.content }}
                        />
                      </InfoBox>
                    );
                  }
                  return null;
                })}
              </InfoBoxWrapper>
            )}
          </Box>

          <Flex
            width="100%"
            flexDirection={["column", "row"]}
            justifyContent="space-between"
          >
            {priceIncludes && (
              <Box width={["100%", "32%"]}>
                <h2>Cena zahrnuje</h2>
                <div dangerouslySetInnerHTML={{ __html: priceIncludes }} />
              </Box>
            )}

            {priceNotIncludes && (
              <Box width={["100%", "32%"]}>
                <h2>Cena nezahrnuje</h2>
                <div dangerouslySetInnerHTML={{ __html: priceNotIncludes }} />
              </Box>
            )}

            {checkInOut && (
              <Box width={["100%", "32%"]}>
                <h2>Příjezd a odjezd</h2>
                <div dangerouslySetInnerHTML={{ __html: checkInOut }} />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </Container>
  </Section>
);

PagePricelistTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

const PagePricelist = ({ data }) => {
  const { wordpressWpMphbRoomType: page, site } = data;
  // const { acf } = page;

  return (
    <Layout>
      <Helmet title={`${page.title} | ${site.siteMetadata.title}`} />
      <PagePricelistTemplate
        title={page.title}
        price_includes={page.acf.price_includes}
        price_not_includes={page.acf.price_not_includes}
        check_in_out={page.acf.check_in_out}
        basic_prices={page.acf.basic_prices}
        special_offers={page.acf.special_offers}
      />
    </Layout>
  );
};

PagePricelist.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PagePricelist;

export const pageQuery = graphql`
  query RoomPricelist($id: String!) {
    wordpressWpMphbRoomType(id: { eq: $id }) {
      title
      content
      acf {
        price_includes
        price_not_includes
        check_in_out
        basic_prices {
          content
        }
        special_offers {
          content
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
